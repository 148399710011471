import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {GameSectionComponent} from './sections/game-section/game-section.component';
import {PressSectionComponent} from './sections/press-section/press-section.component';

const routes: Routes = [
  { path: 'game', component: GameSectionComponent, data: {isMainPage: true} },
  { path: 'presskit', component: PressSectionComponent, data: {isMainPage: false} },
  { path: '**', component: GameSectionComponent, data: {isMainPage: true} },
  { path: '', redirectTo: '/game', pathMatch: 'full', data: {isMainPage: true} },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
