import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'htn-press-section',
  templateUrl: './press-section.component.html',
  styleUrls: ['./press-section.component.scss']
})
export class PressSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
