import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'htn-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {

  steamLogo: string;
  screenshot1Path: string;
  screenshot2Path: string;
  screenshot3Path: string;
  screenshot4Path: string;

  constructor() {
    this.steamLogo = '/assets/images/intro/steamLogo.png';
    this.screenshot1Path = '/assets/images/intro/Screenshot1.png';
    this.screenshot2Path = '/assets/images/intro/Screenshot2.png';
    this.screenshot3Path = '/assets/images/intro/Screenshot3.png';
    this.screenshot4Path = '/assets/images/intro/Screenshot4.png';
  }

  ngOnInit() {
  }
}
