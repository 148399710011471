import {Component, HostListener, OnInit} from '@angular/core';
import {Form} from './form';
import {ContactFormService} from './contact-form.service';
import {ToastService} from 'ng-uikit-pro-standard';
import {TranslateService} from '@ngx-translate/core';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'htn-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  form: Form;
  moonlightLogoPath: string;
  submitted = false;
  templateChecked = false;
  type = 'screen';

  constructor(private contactFormService: ContactFormService, private toastService: ToastService, private translate: TranslateService) {
  }

  ngOnInit() {
    this.form = new Form();
    this.moonlightLogoPath = '/assets/images/contact-form/moonlightLogo.png';
    this.checkWindowSize();
  }

  public onSubmit(form: NgForm) {
    this.submitted = false;
    this.form.policyAccepted = this.templateChecked;
    this.contactFormService.sendForm(this.form);
    this.submitted = true;
    this.templateChecked = false;
    this.showSuccess();
    form.resetForm();
  }

  openNav() {
    document.getElementById('termsAndConditions').style.width = '100%';
  }

  closeNav() {
    document.getElementById('termsAndConditions').style.width = '0';
  }

  showSuccess() {
    this.translate.get('Contact_13').subscribe(value => {
        this.toastService.success(value);
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkWindowSize();
  }

  private checkWindowSize() {
    if (window.innerWidth / window.innerHeight < 1.5 && window.innerWidth / window.innerHeight > 1.1) {
      this.type = 'tablet';
    } else {
      if (window.innerHeight < window.innerWidth) {
        this.type = 'screen';
      } else {
        this.type = 'mobile';
      }
    }
  }
}
