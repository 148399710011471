import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'htn-cover',
  templateUrl: './cover.component.html',
  styleUrls: ['./cover.component.scss']
})
export class CoverComponent implements OnInit {

  coverVideoPath: string;

  constructor() {
    this.coverVideoPath = '/assets/videos/cover_video.mp4';
  }

  ngOnInit() {
  }

}
