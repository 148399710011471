import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'htn-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {

  mediaBackground: string;

  ngOnInit(): void {
  }

  constructor() {
    this.mediaBackground = '/assets/images/media/media-background-2.png';
  }
}
