import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'htn-press-kit',
  templateUrl: './press-kit.component.html',
  styleUrls: ['./press-kit.component.scss']
})
export class PressKitComponent implements OnInit {

  pressKitBackgroundPath: string;
  pressKitZipPath: string;
  banner01Path: string;
  banner02Path: string;
  banner03Path: string;
  screenshot01Path: string;
  screenshot02Path: string;
  screenshot03Path: string;
  screenshot04Path: string;
  screenshot05Path: string;
  screenshot06Path: string;
  screenshot07Path: string;
  screenshot08Path: string;
  screenshot09Path: string;
  screenshot10Path: string;
  screenshot11Path: string;
  screenshot12Path: string;
  screenshot13Path: string;
  screenshot14Path: string;
  screenshot15Path: string;
  screenshot16Path: string;
  screenshot17Path: string;

  constructor() {
    this.pressKitBackgroundPath = '/assets/images/press-kit/pressKitBackground.png';
    this.pressKitZipPath = '/assets/images/press-kit/presskit.zip';
    this.banner01Path = '/assets/images/press-kit/banner-01.jpg';
    this.banner02Path = '/assets/images/press-kit/banner-02.jpg';
    this.banner03Path = '/assets/images/press-kit/banner-03.jpg';
    this.screenshot01Path = '/assets/images/press-kit/screenshot-01.png';
    this.screenshot02Path = '/assets/images/press-kit/screenshot-02.png';
    this.screenshot03Path = '/assets/images/press-kit/screenshot-03.png';
    this.screenshot04Path = '/assets/images/press-kit/screenshot-04.png';
    this.screenshot05Path = '/assets/images/press-kit/screenshot-05.png';
    this.screenshot06Path = '/assets/images/press-kit/screenshot-06.png';
    this.screenshot07Path = '/assets/images/press-kit/screenshot-07.png';
    this.screenshot08Path = '/assets/images/press-kit/screenshot-08.png';
    this.screenshot09Path = '/assets/images/press-kit/screenshot-09.png';
    this.screenshot10Path = '/assets/images/press-kit/screenshot-10.png';
    this.screenshot11Path = '/assets/images/press-kit/screenshot-11.png';
    this.screenshot12Path = '/assets/images/press-kit/screenshot-12.png';
    this.screenshot13Path = '/assets/images/press-kit/screenshot-13.png';
    this.screenshot14Path = '/assets/images/press-kit/screenshot-14.png';
    this.screenshot15Path = '/assets/images/press-kit/screenshot-15.png';
    this.screenshot16Path = '/assets/images/press-kit/screenshot-16.png';
    this.screenshot17Path = '/assets/images/press-kit/screenshot-17.png';
  }

  ngOnInit(): void {
  }
}
