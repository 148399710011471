import {Component, HostListener, Input, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'htn-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() isMainPage = true;

  smallLogoPath: string;
  separatorPath: string;
  sticky: number;
  type: string;
  language: string;

  ngOnInit() {
    this.sticky = document.getElementById('myHeader').offsetTop;
  }

  constructor(private translate: TranslateService) {
    this.smallLogoPath = '/assets/images/header/smallLogo.png';
    this.separatorPath = '/assets/images/header/separator.png';
    this.language = translate.getBrowserLang();
    translate.setDefaultLang(this.language);
    translate.use(this.language);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.checkHeaderStyle();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkWindowSize();
  }

  private checkHeaderStyle() {
    const header = document.getElementById('myHeader');

    if (this.isMainPage) {
      if (document.getElementById('myHeader').offsetTop > this.sticky) {
        this.sticky = document.getElementById('myHeader').offsetTop;
      }

      if (window.pageYOffset >= this.sticky) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    } else {
      header.classList.add('sticky');
    }
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }

  openNav() {
    document.getElementById('mySidenav').style.width = '100%';
  }

  closeNav() {
    document.getElementById('mySidenav').style.width = '0';
  }

  private checkWindowSize() {
    if (window.innerWidth / window.innerHeight < 1.5 && window.innerWidth / window.innerHeight > 1.1) {
      this.type = 'tablet';
    } else {
      if (window.innerHeight < window.innerWidth) {
        this.type = 'screen';
      } else {
        this.type = 'mobile';
      }
    }
  }
}
