import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'htn-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  screenshot1Path: string;
  screenshot2Path: string;
  screenshot3Path: string;
  screenshot4Path: string;
  screenshot5Path: string;
  screenshot6Path: string;
  screenshot7Path: string;

  constructor() {
    this.screenshot1Path = '/assets/images/gallery/Screenshot1.png';
    this.screenshot2Path = '/assets/images/gallery/Screenshot2.png';
    this.screenshot3Path = '/assets/images/gallery/Screenshot3.png';
    this.screenshot4Path = '/assets/images/gallery/Screenshot4.png';
    this.screenshot5Path = '/assets/images/gallery/Screenshot5.png';
    this.screenshot6Path = '/assets/images/gallery/Screenshot6.png';
    this.screenshot7Path = '/assets/images/gallery/Screenshot7.png';
  }

  ngOnInit(): void {
  }
}
